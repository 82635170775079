import {
  ArrayInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';
import { CustomEdit, EditForm } from '../../components/edit';
import { RemoveButton, AddButton } from '../../components/common';

const transform = (data) => {
  const { files, ...formData } = data;
  const filesObject =
    files?.reduce((acc, file) => {
      acc[file.fileName] = file.url;
      return acc;
    }, {}) || {};
  return { ...formData, files: filesObject };
};

const VersionEdit = (props) => {
  const translate = useTranslate();
  const root = 'resources.versions';
  return (
    <>
      <CustomEdit mutationMode="pessimistic" transform={transform} {...props}>
        <EditForm
          variant="outlined"
          ressetable
          titleProps={{
            subsectionTitle: translate(`${root}.breadcrumb.editTitle`),
          }}
        >
          <TextInput source="name" validate={[required()]} fullWidth />
          <TextInput
            variant="outlined"
            source="description"
            options={{ multiline: true, minRows: 4 }}
            fullWidth={true}
            resettable
          />
          <ArrayInput source="files">
            <SimpleFormIterator
              disableReordering
              getItemLabel={() => ''}
              addButton={<AddButton />}
              removeButton={<RemoveButton />}
            >
              <TextInput source="fileName" />
              <TextInput source="url" />
            </SimpleFormIterator>
          </ArrayInput>
        </EditForm>
      </CustomEdit>
    </>
  );
};

export default VersionEdit;
