import {
  ArrayInput,
  Create,
  DateInput,
  required,
  SimpleFormIterator,
  TextInput,
  useTranslate,
} from 'react-admin';
import { FormContainer } from '../../components/common';
import TitleViews from '../../components/common/TitleViews';
import { CreateForm } from '../../components/create';
const VersionCreate = (props) => {
  const translate = useTranslate();
  const root = 'resources.versions';

  const transform = (data) => {
    const { files, ...formData } = data;
    const filesObject =
      files?.reduce((acc, file) => {
        acc[file.fileName] = file.url;
        return acc;
      }, {}) || {};
    return { ...formData, files: filesObject };
  };

  return (
    <>
      <TitleViews
        subsectionTitle={translate(`${root}.breadcrumb.createTitle`)}
        title={`${translate('labels.create')} ${translate(`${root}.title`)}`}
      />
      <Create component={FormContainer} transform={transform} {...props}>
        <CreateForm variant="outlined" resettable>
          <TextInput source="name" validate={[required()]} fullWidth />
          <TextInput
            variant="outlined"
            source="description"
            options={{ multiline: true, minRows: 4 }}
            fullWidth={true}
            resettable
            validate={required()}
          />
          <DateInput source="date" fullWidth validate={[required()]} />
          <ArrayInput source="files">
            <SimpleFormIterator disableReordering getItemLabel={() => ''}>
              <TextInput source="fileName" />
              <TextInput source="url" />
            </SimpleFormIterator>
          </ArrayInput>
        </CreateForm>
      </Create>
    </>
  );
};

export default VersionCreate;
